import { autoinject, bindable, computedFrom, containerless } from 'aurelia-framework';
import { Toast } from 'utilities/toast';
import { CasesApiClient, InvestigationsApiClient } from '../../../services/cyber-api';
import { StateApi } from '../../../services/state-api';

@autoinject()
@containerless()
export class OrderInvestigationBlade {
    private keypressCallback: EventListenerOrEventListenerObject;
    @bindable() private caseBookId: string;
    @bindable() private agreedWithTerms: boolean = false;
    @bindable() private onHide: () => void = () => { };
    private creditBalance: number;
    private isOrderingInvestigation: boolean = false;

    constructor(private caseApi: CasesApiClient, private investigationsApi: InvestigationsApiClient, private state: StateApi) {
        this.keypressCallback = this.keypress.bind(this);
    }

    public async cancel(): Promise<void> {
        this.onHide();
    }

    public async requestInvestigation(): Promise<void> {
        if (
            !this.agreedWithTerms // User needs to agree with the terms to order an investigation
            || this.isOrderingInvestigation // An investigation may not already be ordered
        ) return;

        // Disable the 'order investigation' button, prevents multiple requests
        this.isOrderingInvestigation = true;

        try {
            const response = await this.investigationsApi.request(this.caseBookId, this.state.company());
            Toast.success('Investigation successfully requested.');

            // Re-enable the 'order investigation' button
            this.isOrderingInvestigation = false;

            this.onHide();
        } catch (error) {
            Toast.error('Backend reports an error when ordering investigation.');
            throw error;
        }
    }

    //#region Keypress Events for [Esc]

    private async attached(): Promise<void> {
        try {
            this.creditBalance = await this.investigationsApi.getBalance(this.state.company());
        } catch (error) {
            Toast.error('Backend reports an error on getting your creditbalance.');
            this.creditBalance = 0;
        }

        window.addEventListener('keydown', this.keypressCallback, false);
    }

    private async detached(): Promise<void> {
        this.agreedWithTerms = false;
        window.removeEventListener('keydown', this.keypressCallback);
    }

    private async keypress(e: KeyboardEvent): Promise<void> {
        if (e.code === 'Escape') this.onHide();
    }

    //#endregion

    @computedFrom('caseBookId')
    private get supportEmailLink(): string {
        return 'mailto:servicedesk@marlink.com'
            + `?subject=Investigation order for Case ${this.caseBookId} (company ${this.state.company()})`
            + `&body=Hi, I would like to order an Investigation for Case https://cyber.apps.marlink.io/cases/${this.caseBookId} for company ${this.state.company()}.`;
    }
}
