import { autoinject, bindable, containerless, observable } from 'aurelia-framework';
import { EdrAgentResult, EdrApiClient, EdrHost, EdrScan, ScanResponse, ScanType } from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { Router } from 'aurelia-router';

@containerless()
@autoinject()
export class FleetHealthBlade {
    @bindable() private agent: EdrHost;
    @bindable() private agentResult: EdrAgentResult;
    @bindable() private openQuarantines: boolean = false;
    @bindable() private onHide: () => void = () => {
    };

    constructor(
        private state: StateApi,
        private edrApi: EdrApiClient,
        private router: Router,
    ) {
    }

    private async agentChanged(): Promise<void> {
        this.openQuarantines = false;
    }
}
