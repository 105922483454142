import { autoinject, bindable, containerless } from 'aurelia-framework';
import {
    EdrAgentResult,
    EdrApiClient, EdrContainment,
    EdrHost,
    EdrHostContainmentState,
    EdrScan,
    ScanResponse,
    ScanType
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { Router } from 'aurelia-router';
import moment from 'moment/moment';
import { environment } from 'utilities/environment';
import Swal from 'sweetalert2';
import { Toast } from '../../../../../utilities/toast';

@containerless()
@autoinject()
export class HostInformation {
    @bindable() private agent: EdrHost;
    @bindable() private agentResult: EdrAgentResult;
    @bindable() private onHide: () => void = () => {
    };

    private readonly keypressCallback: EventListenerOrEventListenerObject;
    @bindable private loading: boolean = true;
    @bindable private openQuarantines: boolean;
    private latestScan: EdrScan;
    private environment = environment;
    private isContained = false;
    private containmentLoading = false;

    private selectedScanType: ScanType = ScanType.Full;
    private scanRequested: boolean = false;
    private scanResponse: ScanResponse;
    private ScanType: typeof ScanType = ScanType;
    private EdrHostContainmentStates: typeof EdrHostContainmentState = EdrHostContainmentState;

    constructor(
        private state: StateApi,
        private edrApi: EdrApiClient,
        private router: Router,
    ) {
        this.keypressCallback = this.keypress.bind(this);
    }

    private showQuarantines(): void {
        this.openQuarantines = true;
        this.detached();
    }

    private async agentChanged(): Promise<void> {
        this.loading = true;
        this.scanResponse = null;
        // Retrieve the agent info
        if (this.agent)
            this.latestScan = (await this.edrApi.scans(this.state.company(), this.agent._id, 1, 0)).items[0];
            this.setIsContained();

        this.loading = false;
    }

    private setIsContained(): void {
        this.isContained = this.agent.containment_state === EdrHostContainmentState.Contained || this.agent.containment_state === EdrHostContainmentState.Contain || this.agent.containment_state === EdrHostContainmentState.Containing;
    }

    private navigateToVessel(): void {
        // As routing to a child route of a different parent route is not possible, navigate to the URL fragment instead
        // See https://github.com/aurelia/router/issues/89
        // where https://github.com/aurelia/router/issues/89#issuecomment-282079392 could be a solution.
        const siteId = this.agentResult.siteId.replace('SID', '');
        this.router.navigate(`/vessels/${siteId}`);
    }

    private async startScan(): Promise<void> {
        this.scanRequested = true;
        this.scanResponse = await this.edrApi.startScan(this.agent._id, this.state.company(), this.selectedScanType);
        this.scanRequested = false;
    }

    //#region Keypress Events for [Esc]

    private async attached(): Promise<void> {
        window.addEventListener('keydown', this.keypressCallback, false);
    }

    private async detached(): Promise<void> {
        window.removeEventListener('keydown', this.keypressCallback);
    }

    private async keypress(e: KeyboardEvent): Promise<void> {
        if (e.code === 'Escape') this.onHide();
    }

    private lessThanThreeDaysAgo = (date) => {
        if (!date) return;
        return moment(date).isAfter(moment().subtract(3, 'days'));
    }

    //#endregion

    public async refreshContainmentState(): Promise<void> {
        this.containmentLoading = true;
        let containment = await this.edrApi.containmentByHostId(this.agent._id, this.state.company());
        if (containment) {
            this.agent.containment_state = containment.state;
        }
        this.setIsContained();
        this.containmentLoading = false;
    }

    public handleContainedChange(value: boolean): void {
        this.containmentLoading = true;
        Swal.fire({
            title: value ? 'Isolate a machine/PC' : 'Release a machine/PC',
            html: `<p>This feature allows you to ${value ? 'isolate a': 'release an isolated'} machine/PC on the vessel.</p>
            <br/>
            ${value ? 
                '<p>After confirming the isolation, this machine/PC would be isolated from other machine(s)/PCs(s) on board which will no longer accept packets from this machine, but you can perform all type of scans on this machine even when it\'s isolated.</p>' : 
                '<p>After confirming the release, this machine/PC would be released from isolation and will be able to communicate with other machine(s)/PC(s) on board.</p>'
            }
            <div class="w-100 text-left d-flex mt-2">
                    <div class='ml-1 mt-1'>
                      <input type="checkbox" id="confirmation" class="swal2-checkbox m-0 align-top">
                    </div>
                    <label for="confirmation" class="form-check-label ml-2">
                        <small> I confirm that I wish to ${value ? 'isolate': 'release'} this machine/PC on the vessel ${this.agentResult.vesselName}</small>
                    </label>
            </div>
            `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Confirm',
            preConfirm: () => {
                let confirmation = document.getElementById('confirmation') as HTMLInputElement;
                if (!confirmation.checked)
                    Swal.showValidationMessage(`You need to confirm that you wish to ${value ? 'isolate': 'release'} the machine/PC.`)
                else
                    return {
                        confirm: confirmation.checked
                    };
            },
        }).then((response) => {

            if (response.value) {
                if (value) {
                    this.edrApi.approveHostContainment(this.agent._id, this.state.company()).then((edrContainment: EdrContainment) => {
                        this.agent.containment_state = edrContainment.state;
                        this.agent.containment_queued = edrContainment.queued;
                        this.agent.containment_missing_software = edrContainment.missing_software;
                        this.agent.reported_clone = edrContainment.reported_clone;

                        this.setIsContained();
                    }).catch((error) => {
                        this.isContained = !value;
                        Toast.error(`Oops, ${error}`);
                    }).finally(() => {
                        this.containmentLoading = false;
                    });
                } else {
                    this.edrApi.deleteHostContainment(this.agent._id, this.state.company()).then((edrContainment: EdrContainment) => {
                        this.agent.containment_state = edrContainment.state;
                        this.agent.containment_queued = edrContainment.queued;
                        this.agent.containment_missing_software = edrContainment.missing_software;
                        this.agent.reported_clone = edrContainment.reported_clone;

                        this.setIsContained();
                    }).catch((error) => {
                        this.isContained = !value;
                        Toast.error(`Oops, ${error}`);
                    }).finally(() => {
                        this.containmentLoading = false;
                    });
                }
            } else {
                // Modal is declined/closed, revert the change in the UI
                this.isContained = !value;
            }
            this.containmentLoading = false;
        });
    }
}
