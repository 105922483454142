import { autoinject, bindable, containerless } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { CaseBook, CasesApiClient } from '../../../services/cyber-api';
import { StateApi } from '../../../services/state-api';

@autoinject()
@containerless()
export class CreateCaseBlade {
    @bindable() private onHide: (caseBook: CaseBook) => void = () => { };
    @bindable() private enableBusinessFeatures: boolean = false;
    @bindable() private navigateAfterCreate: boolean = true;
    private caseTitle: string;
    private caseDescription: string;
    private caseIsShared: boolean = false;
    private caseTitleValid: boolean = true;
    private caseDescriptionValid: boolean = true;
    private saving: boolean = false;
    private keypressCallback: EventListenerOrEventListenerObject;

    constructor(
        private caseApi: CasesApiClient,
        private state: StateApi,
        private router: Router
    ) {
        this.keypressCallback = this.keypress.bind(this);
    }

    public async cancel(): Promise<void> {
        this.clear();
        this.onHide(null);
    }

    public async save(): Promise<void> {
        if (!this.validate()) return;

        this.saving = true;
        const c = new CaseBook();
        c.title = this.caseTitle;
        c.description = this.caseDescription;
        c.isPrivate = !this.caseIsShared;

        try {
            const caseBook = await this.caseApi.create(this.state.company(), c);
            this.saving = false;
            this.onHide(caseBook);

            // As routing to a child route of a different parent route is not possible, navigate to the URL fragment instead
            // See https://github.com/aurelia/router/issues/89
            // where https://github.com/aurelia/router/issues/89#issuecomment-282079392 could be a solution.
            if (this.navigateAfterCreate)
                this.router.navigate(`/cases/${caseBook.id}`);
        } catch (error) {
            this.saving = false;
            throw error;
        }
    }

    private validate(): boolean {
        let valid: boolean = true;

        if (this.caseTitle) this.caseTitleValid = true;
        else this.caseTitleValid = false;

        if (this.caseDescription) this.caseDescriptionValid = true;
        else this.caseDescriptionValid = false;

        valid = this.caseTitleValid && this.caseDescriptionValid;

        return valid;
    }

    private clearValidation(): void {
        this.caseTitleValid = true;
    }

    private clear(): void {
        this.caseTitle = undefined;
        this.caseDescription = undefined;
        this.caseIsShared = false;

        this.clearValidation();
    }

    //#region Keypress Events for [Esc]

    private async attached(): Promise<void> {
        window.addEventListener('keydown', this.keypressCallback, false);
    }

    private async detached(): Promise<void> {
        window.removeEventListener('keydown', this.keypressCallback);
    }

    private async keypress(e: KeyboardEvent): Promise<void> {
        if (e.code === 'Escape') this.onHide(null);
    }

    //#endregion
}
