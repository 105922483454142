import { autoinject, bindable, containerless } from 'aurelia-framework';
import {
    AddFileExclusionRequest,
    EdrAgentResult,
    EdrApiClient,
    EdrHost,
    EdrQuarantineFile,
    GetExclusionsResponse,
    QuarantineAction
} from 'services/cyber-api';
import { StateApi } from 'services/state-api';
import { Utilities } from '../../../../../utilities/utilities';
import { Toast } from '../../../../../utilities/toast';
import Swal from 'sweetalert2';
import { toNumber } from 'lodash';

@containerless()
@autoinject()
export class HostQuarantines {
    @bindable() private agent: EdrHost;
    @bindable() private agentResult: EdrAgentResult;
    @bindable() private onHide: () => void = () => {
    };

    private policy: GetExclusionsResponse;

    private readonly keypressCallback: EventListenerOrEventListenerObject;
    private loading: boolean = true;
    @bindable private openQuarantines: boolean;
    private quarantines: EdrQuarantineFile[];
    private skip: number = 0;
    private take: number = 25;
    private totalCount: number = undefined;

    constructor(
        private state: StateApi,
        private edrApi: EdrApiClient,
    ) {
        this.keypressCallback = this.keypress.bind(this);
    }

    private showQuarantines(): void {
        this.openQuarantines = !this.openQuarantines;
    }

    private async agentChanged(): Promise<void> {
        this.loading = true;
        this.quarantines = [];
        this.totalCount = undefined;
        this.skip = 0;
        // Retrieve the agent info
        if (this.agent) {
            await this.fetchQuarantines(0, true, false);
            this.policy = await this.edrApi.exclusions(this.state.company());
        }

        this.loading = false;
    }

    private async fetchQuarantines(topIndex: number, isAtBottom: boolean, isAtTop: boolean): Promise<void> {
        // Only fetch more when scroll position is at the bottom
        if (!isAtBottom) return;

        // When at the end of the list and no more data is available, short-circuit as there's nothing left
        // to fetch
        if (this.totalCount === this.quarantines.length) return;
        this.loading = true;
        const pagedResult = await this.edrApi.quarantinesByHost(
            this.agent._id,
            this.state.company(),
            this.take,
            this.skip
        );
        if (!pagedResult) {
            this.loading = false;
            return;
        }

        this.quarantines = this.quarantines.concat(pagedResult.items);

        this.totalCount = pagedResult.total;

        this.skip += this.take;

        this.loading = false;
    }

    private async executeRestore(quarantine: EdrQuarantineFile): Promise<void> {
        // Restore the file
        this.policy = await this.edrApi.exclusions(this.state.company());
        let alertGroupId = undefined;
        if (quarantine.alert) {
            // get alertGroup based on alertId
            await this.edrApi.alertGroupByHostAndAlertId(this.agent._id, this.state.company(), toNumber(quarantine.alert._id)).then((alertGroup) => {
                alertGroupId = alertGroup._id;
            }).catch(async (error) => {
                await this.edrApi.alertGroupsByHostAndFilePath(this.agent._id, this.state.company(), quarantine.file_path, 1, 0).then((alertGroups) => {
                    if (alertGroups.items.length !== 0) {
                        alertGroupId = alertGroups.items[0]._id
                    }
                });
            });
        } else {
            // get alertgroup based on filepath
            await this.edrApi.alertGroupsByHostAndFilePath(this.agent._id, this.state.company(), quarantine.file_path, 1, 0).then((alertGroups) => {
                if (alertGroups.items.length !== 0) {
                    alertGroupId = alertGroups.items[0]._id
                }
            });
        }

        // find everything behind the latest slash
        let wildcardValue = quarantine.file_path.split('\\').pop();
        const response = await Swal.fire({
            title: 'Restore file',
            html: `<p>You need to exclude the file from monitoring before restoring it. Restoring will not work fleetwide, but adding a rule will.</p>
             <input id='file-path' class="swal2-input w-100 m-0 mt-3" value='${quarantine.file_path}'>
                <div class="w-100 text-left d-flex mt-2">
                    <div class='ml-1 mt-1'><input type="checkbox" id="apply-wildcard" class="swal2-checkbox m-0 align-top"></div><label for="apply-wildcard" class="form-check-label ml-2"><small>Exclude filename with wildcard (*\\${wildcardValue})
- if possible according to rule</small></label>
                  </div>
                  <div class='d-flex w-100 justify-content-between pt-2'>
                    <div>
                      <p id='rule-info' class='text-info small m-2'>
                        Rule is already in place
                      </p>
                    </div>
                  </div>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No, cancel',
            confirmButtonText: 'Yes, continue',
            preConfirm: async () => {
                return this.addRule((document.getElementById('file-path') as HTMLInputElement).value, this.policy.policyId)
                    .then((_) => {
                        return {
                            filePath: document.getElementById('file-path') as HTMLInputElement
                        };
                    }).catch((errors) => {
                        Swal.showValidationMessage(errors.join('<br>') || 'Something went wrong. Please try again.');
                    })
            },
            didOpen: () => {
                const filePathInput = Swal.getPopup()!.querySelector('#file-path') as HTMLInputElement
                const applyWildcard = Swal.getPopup()!.querySelector('#apply-wildcard') as HTMLInputElement
                const ruleInfo = Swal.getPopup()!.querySelector('#rule-info') as HTMLParagraphElement

                showRuleInfo(this.policy, quarantine);

                function showRuleInfo(policy: GetExclusionsResponse, quarantineFile: EdrQuarantineFile) {
                    let filePathExcluded = policy.excludedFiles.find(x => x === quarantineFile.file_path)?.length > 0;
                    let wildcardExcluded = policy.excludedFiles.find(x => x === `*\\${wildcardValue}`)?.length > 0;

                    let ruleExists = applyWildcard.checked ? wildcardExcluded : filePathExcluded;

                    if (ruleExists) {
                        ruleInfo.classList.toggle('d-none', false);
                    } else {
                        ruleInfo.classList.toggle('d-none', true);
                    }
                }

                applyWildcard.addEventListener('change', () => {
                    filePathInput.value = applyWildcard.checked ? `*\\${wildcardValue}` : quarantine.file_path;
                    showRuleInfo(this.policy, quarantine);
                });
            },
        });

        if (response.value) {
            try {
                debugger;
                quarantine = await this.edrApi.quarantineAction(
                    quarantine._id.toString(),
                    alertGroupId ?? '000', // it is possible to have no alertGroupId if it does not exist for some reason.
                    this.state.company(),
                    QuarantineAction.Restore,
                    this.agent._id,
                    quarantine.file_path);
                Toast.success('successfully restored file');
            } catch (error) {
                console.log(error);
                Toast.error(`Oops, ${error}`);
            } finally {
                await this.agentChanged();
            }
        }
    }


    private async addRule(filePath: string, policyId: string): Promise<void> {
        let addFileExclusionRequest = new AddFileExclusionRequest({
            policyId: policyId,
            file: filePath
        });

        await this.edrApi.addFileExclusion(this.state.company(), addFileExclusionRequest);
        this.policy = await this.edrApi.exclusions(this.state.company());
        Toast.success('successfully excluded file from monitoring');
    }

    //#region Keypress Events for [Esc]

    private async attached(): Promise<void> {
        window.addEventListener('keydown', this.keypressCallback, false);
    }

    private async detached(): Promise<void> {
        window.removeEventListener('keydown', this.keypressCallback);
    }

    private async keypress(e: KeyboardEvent): Promise<void> {
        if (e.code === 'Escape') this.back();
    }

    private back(): void {
        this.openQuarantines = false;
        this.detached();
    }

    //#endregion
    private copyToClipboard(value: string): void {
        Utilities.copyToClipboard(value);

        Toast.info('Copied to clipboard');
    }
}
